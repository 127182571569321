import type SwiperClass from 'swiper/types/swiper-class';
import type { GameMinimalProps, Children } from '../../types';
import type { SwiperOptions } from 'swiper/types/swiper-options';

import * as React from 'react';
import styled from '@emotion/styled';
import { CardAsset } from '../Card';
import { Link } from '../Link';
import { useAppContext } from '../../contexts/AppContext';
import CardActionArea from '@mui/material/CardActionArea';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, A11y, Thumbs, Virtual } from 'swiper';
import 'swiper/css';
import 'swiper/css/thumbs';

export interface ListProps extends SwiperOptions, Children {
  items: GameMinimalProps[];
  // cardOptions: CardPropsOmit;
  className?: string;
}

const SwiperContainer = styled('div')(() => ({
  width: '100%',
  overflow: 'hidden',
  '& > .swiper-autoheight': {
    height: '100%',
  }
}));

export const CarouselThumbs = (props: ListProps) => {
  const { items, className, ...swiperOptions } = props;
  const appContext = useAppContext();
  const { assetUrl, componentOptions } = appContext.useConfig();
  const channel = React.useRef((new Date()).getTime().toString(36));
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperClass>();
  const [slides] = React.useState<GameMinimalProps[]>(items || []);

  return (
    <>
      <SwiperContainer>
        <Swiper
          spaceBetween={0}
          centeredSlides={false}
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          modules={[Autoplay, A11y, Thumbs, Virtual]}
          virtual
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          {...swiperOptions}
          // style={{
          //   "--swiper-navigation-color": "#fff",
          //   "--swiper-pagination-color": "#fff",
          // }}
        >
          {slides.map((item: GameMinimalProps, index) => (
            <SwiperSlide
              key={`${channel?.current}${index}a`}
              virtualIndex={index}
            >
              {item?.id && (
                <CardActionArea
                  component={Link}
                  href={`/game/${item.slug}`}
                  title={item.name || undefined}
                >
                  <CardAsset
                    src={`${assetUrl}${item.images.cover16x9 || item.images.cover}/`}
                    alt={item.name || 'Cover'}
                    width={componentOptions.component_card_width || 512}
                    height={componentOptions.component_card_height || 288}
                    priority={index === 0}
                    layout="responsive"
                    sizes={'(min-width:1200px) 33.333vw, (min-width:900px) 66.333vw, 100vw'}
                  />
                </CardActionArea>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
      <SwiperContainer>
        <Swiper
          spaceBetween={0}
          slidesPerView={4}
          onSwiper={setThumbsSwiper}
          modules={[A11y, Thumbs, Virtual]}
          virtual
          breakpoints={{
            1536: { // xl
              direction: 'vertical',
              autoHeight: true,
            },
            0: {
              direction: 'horizontal',
              slidesPerView: 4,
            },
          }}
          // style={{
          //   "--swiper-navigation-color": "#fff",
          //   "--swiper-pagination-color": "#fff",
          // }}
        >
          {(items || []).map((item: GameMinimalProps, index) => (
            <SwiperSlide
              key={`${channel?.current}${index}b`}
              virtualIndex={index}
            >
              {item?.id && (
                <CardActionArea>
                  <CardAsset
                    src={`${assetUrl}${item.images.cover16x9 || item.images.cover}/`}
                    alt={item.name || 'Cover'}
                    width={componentOptions.component_card_width || 512}
                    height={componentOptions.component_card_height || 288}
                    layout="responsive"
                    sizes={'(min-width:1200px) 8.333vw, (min-width:900px) 12.5vw, 25vw'}
                  />
                </CardActionArea>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
    </>
  );
};

export default CarouselThumbs;